import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "animate.css/animate.min.css"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import BaiduMap from 'vue-baidu-map-3x'
import echarts from '@/plugins/echarts.js'
import locale from 'element-plus/lib/locale/lang/zh-cn';

const app = createApp(App)
app.use(BaiduMap, { ak: 'mbsEm1XAisk0lV62Zxp5yU0yBGGVGMcO', v: '2.0' })
app.use(ElementPlus, { locale })
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store)
app.use(router)
app.mount('#app')

app.config.globalProperties.$echarts = echarts;